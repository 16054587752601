  .Mslider-main{
    position: relative;
    /* margin: 2rem !important; */
    margin-bottom: 1rem !important;
    margin-top: 1.5rem;
  }
  
  
  
  .Mslider {
    position: relative;
    width: 100%;
    padding-bottom: 1rem !important;
  }
  
  .Mslider__track,
  .Mslider__range,
  .Mslider__left-value,
  .Mslider__right-value {
    position: absolute;
  }
  
  .Mslider__track,
  .Mslider__range {
    border-radius: 3px;
    height: 5px;
  }
  
  .Mslider__track {
    background-color: #ced4da;
    color: black !important;
    width: 100%;
    z-index: 1;
  }
  
  .Mslider__range {
    background-color: #ff9100;
    z-index: 2;
  }
  
  .Mslider__left-value,
  .Mslider__right-value {
    color: #000;
    font-size: 15px;
    margin-top: 20px;
  }
  
  .Mslider__left-value {
    left: 6px;
  }
  
  .Mslider__right-value {
    right: -4px;
  }
  
  /* Removing the default appearance */
  .Mthumb,
  .Mthumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }
  
  .Mthumb {
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 100%;
    outline: none;
  }
  
  .Mthumb--zindex-3 {
    z-index: 3;
  }
  
  .Mthumb--zindex-4 {
    z-index: 4;
  }
  
  .Mthumb--zindex-5 {
    z-index: 5;
  }
  
  /* For Chrome browsers */
  .Mthumb::-webkit-slider-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }
  
  /* For Firefox browsers */
  .Mthumb::-moz-range-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }
  

  .mslider_input{
    max-width: 100px;

  }